
<template>
  <div class="auth-page-wrapper pt-5">
    <header id="page-topbar">
      <div class="layout-width">
        <div
          class="navbar-header"
          style="height: 60px"
        >
          <div>
            <div class="d-flex">
              <div class="d-none d-md-flex">
                <router-link to="/">
                  <img
                    src="@/assets/images/logo/polytron-logo.png"
                    alt=""
                    height="18"
                    class="my-auto mx-auto"
                  >
                </router-link>
              </div>
              <div class="ms-3">
                <span class="fw-bold fs-md-4 fs-5">
                  SMART FLEET MANAGEMENT SYSTEM
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="navbar-header text-white bg-dark"
          style="height: 40px;"
        />
      </div>
    </header>
    <div
      class="auth-page-content"
      style="margin-top: 50px; height: 85vh; background-color: #F5F5F5;"
    >
      <div class="container h-100">
        <div class="row justify-content-center h-100">
          <div class="col-md-8 col-lg-6 col-xl-5 my-auto">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">
                    Welcome Back !
                  </h5>
                  <p class="text-muted">
                    Sign in to continue to Smart Fleet Management System
                  </p>
                </div>
                <div
                  class="alert alert-danger alert-dismissible mt-3 mb-0 border-danger border-top-0 border-end-0 border-bottom-0"
                  role="alert"
                  :class="hasError ? '' : 'd-none'"
                  style="border-left-width: 4px;"
                >
                  <div>
                    Email and password are invalid.
                  </div>
                  <div>
                    Please enter correct email and password.
                  </div><button
                    type="button"
                    class="btn-close h-100 p-0 pe-3"
                    aria-label="Close"
                    @click="hasError = false"
                  />
                </div>

                <div class="p-2 mt-3">
                  <form @submit.prevent="login">
                    <b-form-group
                      class="required"
                      label="Email"
                      label-for="input-email"
                    >
                      <b-form-input
                        id="input-email"
                        v-model="form.email"
                        type="email"
                        placeholder="Enter Email"
                        required
                        class="form-control"
                        :class="{
                          'is-invalid': v$.form.email.$error,
                        }"
                      />
                      <div
                        v-for="(item, index) in v$.form.email.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="required"
                      label="Password"
                      label-for="input-password"
                    >
                      <b-input-group>
                        <b-form-input
                          id="input-password"
                          v-model="form.password"
                          :type="showPassword ? 'text': 'password'"
                          class="form-control pe-5"
                          :class="{
                            'is-invalid': v$.form.password.$error,
                          }"
                          style="
                            border-top-right-radius: 0.25rem;
                            border-bottom-right-radius: 0.25rem;
                          "
                          placeholder="Enter Password"
                          required
                        />
                        <b-input-group-append>
                          <div
                            class="d-flex cancel-search-icon h-100 px-3"
                            style="position: relative;"
                            :class="v$.form.password.$error ? 'is-invalid' : ''"
                            @click="showPassword = !showPassword"
                          >
                            <i
                              class="my-auto"
                              :class="showPassword ? 'bx bx-hide': 'bx bx-show-alt'"
                            />
                          </div>
                        </b-input-group-append>
                        <div
                          v-for="(item, index) in v$.form.password.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                      </b-input-group>
                      <div class="text-muted mt-1">
                        <small>Passwords must contain at least six characters.</small>
                      </div>
                    </b-form-group>
                    <div class="mt-4">
                      <b-button
                        variant="primary"
                        class="w-100"
                        type="submit"
                      >
                        Sign In
                      </b-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, email, helpers } from "@vuelidate/validators";
import { TokenService } from '@/services/local-storage';
import useVuelidate from "@vuelidate/core";
import appConfig from "@/app.config";
import Footer from "@/components/footer";

export default {
  components: {
    Footer,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      showPassword: false,
      isLoading: false,
      hasError: false,
    };
  },
  computed: {
    isError() {
      return Boolean(this.errorStatus()) 
    }
  },
  watch: {
    isError: {
      immediate: true,
      handler(val) {
        this.hasError = val
      },
    },
  },
  validations: { 
    form : {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    }
  },
  created() {
    const isLoggedIn = !!TokenService.get();
    if (isLoggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    ...mapState('auth', ['errorStatus']),
    ...mapActions('auth', ['userLogin']),
    async login() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        await this.userLogin({
            ...this.form,
          });
      }
    },
  },
};
</script>